@use '@angular/material' as mat;
@import "@angular/material/theming";

@mixin buttons-theme($sps-app-theme) {
  $primary: map-get($sps-app-theme, primary);
  $accent: map-get($sps-app-theme, accent);
  $warning: map-get($sps-app-theme, warn);

  .button-row {
    button {
      margin: 4px;
      opacity: 1 !important;
    }
  }

  .btn-group {
    .btn,
    .mat-mdc-button,
    .mat-mdc-outlined-button {
      margin: 0px !important;
      height: 56px;
    }
  }

  .upload-btn{
    display: flex !important;
    align-items: center !important;
  }
  .mat-mdc-button-toggle-group{
    height: 58px;
    .mat-mdc-button-toggle-button{
      height: inherit;
    }
  }

  .no-dropdown{
    width: 100px;
  }

  .btn-group.btn-dropdown{
    max-width: 100px;
    .mat-mdc-outlined-button.dropdown {
        border-radius: 0 4px 4px 0 !important;
        padding: 2px;
    }
    .mat-mdc-outlined-button.action {
      border-radius: 4px 0 0 4px !important;
      border-right: 0px;
  }
  }

  .btn,
  .mat-mdc-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button {
    height: 52px !important;
    margin: 4px !important;
  }

  .mat-mdc-outlined-button{
    background-color: mat.get-color-from-palette($primary, 0.2);
  }

  button {
    outline: none !important;
  }

  .mat-mdc-radio-button,
  .mat-mdc-checkbox {
    margin-right: 16px;
  }

  .mat-mdc-chip {
    .mat-icon {
      font-size: 20px;
      height: 20px !important;
      width: 20px !important;
      margin: 4px 4px 4px 0;
    }
    width: 200px;
    white-space: nowrap !important;
    overflow: hidden !important;
  }

  .mat-mdc-checkbox,
  .mat-mdc-radio-button {
    p {
      margin-bottom: 0px;
    }
    .mat-mdc-checkbox-layout,
    .mat-mdc-radio-label {
      margin-bottom: 0px;
    }
  }
  .mat-mdc-button-sm {
    .material-icons {
      font-size: 20px !important;
    }
  }

  @media only screen and (max-width: 1100px) {
    .mat-mdc-button-toggle-group, .mat-mdc-button-toggle-standalone {
      flex-direction: column !important;
      .mat-mdc-button-toggle{
        border-bottom: 0.5px solid mat.get-color-from-palette($primary);
      }
    }
  }
}
