@import '@angular/material/theming';

@mixin typography-theme($sps-app-theme) {
  $primary: map-get($sps-app-theme, primary);
  $accent: map-get($sps-app-theme, accent);
  $warning: map-get($sps-app-theme, warn);

  .mat-mdc-form-field-hint, .sm-font{
    font-size: 75%;
  }

  .mat-mdc-form-field{
    .mat-mdc-form-field-hint{
      font-size: 100%;
    }
  }
  
  .mat-mdc-tooltip{
    font-size: 14px;
  }

}
