@use '@angular/material' as mat;
@import '../../../../../node_modules/@angular/material/theming';

@mixin dashboard-theme($sps-app-theme) {
  $primary: map-get($sps-app-theme, primary);
  $accent: map-get($sps-app-theme, accent);
  $warning: map-get($sps-app-theme, warn);

.mat-mdc-card.shift{
    padding: 0px !important;
    margin: 8px 0 16px 0 !important;
    min-height:260px;
    .mat-mdc-card-content, .mat-mdc-card-actions{
        padding: 16px;
        margin-bottom: 0px;
      .card-header{
          padding: 4px;
      }
    }
  }

  .mat-mdc-card.compulsory {
    padding: 0px !important;
    margin: 8px 0 16px 0 !important;
    min-height: 150px;

    .mat-mdc-card-content, .mat-mdc-card-actions {
      padding: 16px;
      margin-bottom: 0px;

      .card-header {
        padding: 4px;
      }
    }
  }

  button.btn.badge {
    height: 100%;
    margin: 0 !important;
  }

  .modal-content{
    overflow-x: hidden !important;
  }

  .bg-light-grey{
    background-color: mat.get-color-from-palette($primary, 0.4) !important;
  }


  .bg-info{
    background-color: #FFE8D1 !important;
  }

  [class*='col-']{
    .mat-mdc-card{
        margin: 0px;
    }
  }

  .material-icons{
      cursor: pointer;
      &:hover{
          opacity: 0.7;
      }
  }

  .error-banner{
    height: 250px;
    width: 100%;
  }


// .mat-hint{
//     color: black;
// }

.btn-group{
  .btn, .mat-mdc-button, .mat-mdc-outlined-button{
    margin: 0px !important;
  }
}

// .mat-progress-bar{
//   width: 100vw !important;
// }
.mat-mdc-card.rounded-right{
  border-radius: 0 4px 4px 0;
}

.card-row-title {
    display: flex!important;
    align-items: center;
    flex-wrap: inherit;
}
.mat-mdc-card.rounded-left{
  border-radius: 4px 0 0 4px;
}

.mat-mdc-dialog-surface {
    padding: 20px 40px!important;
    border: 5px solid #d3d3d3;
    border-radius: 5px;
}

}

.average-duration {
 display: inline;
}

mat-card {
    justify-content: center;
}
