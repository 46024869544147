/* You can add global styles to this file, and also import other style files */


/* For left alignment of mat-grid-tiles. material has no native alignment technique*/
.mat-grid-tile .mat-figure {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

/*Custom Scrollbars*/

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: none;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 10px;
  border: solid 2px white;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999999;
  border: none;
}

.mat-mdc-unelevated-button > .mat-icon, .mat-mdc-raised-button > .mat-icon, .mat-mdc-outlined-button > .mat-icon {
    width: unset !important;
    height: unset !important;
    margin: 0 !important;
}


//Custom mat chip styling

.presentation-chips {
    mat-chip {
        width: fit-content !important;
    }
}
.page-count{
    position: absolute!important;
    top: 95% !important;
    z-index: 99!important;
    .mdc-evolution-chip__cell {
        justify-content: center!important;
    }
}
.hidden-slide{
    position: absolute!important;
    top: 91%;
    left: 45%;
    z-index: 99!important;
    cursor: pointer;
    width: fit-content !important;
    &:hover {
        opacity: .8;
    }
}
.mdc-evolution-chip__text-label {
    color: inherit !important;
}


//Remove form field subscript
.no-subscript {
    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }
}

//remove form field border radius
.remove-left-border {
    .mdc-notched-outline__leading {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

//Remove radio button padding
.mat-mdc-radio-button .mdc-radio {
    padding: 0 !important;
}
