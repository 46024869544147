@use '@angular/material' as mat;
@import "@angular/material/theming";

@mixin form-theme($sps-app-theme) {
  $primary: map-get($sps-app-theme, primary);
  $accent: map-get($sps-app-theme, accent);
  $warning: map-get($sps-app-theme, warn);
  .no-underline .mat-mdc-form-field-underline {
    background-color: white !important;
  }
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0px;
  }

  .stretch-button-group {
    .mat-mdc-form-field-outline-start {
      border-radius: 0px !important;
    }
    .mat-mdc-button-toggle {
      width: inherit;
    }
    .mat-mdc-button-toggle-group {
      min-width: 192px;
      border-radius: 4px 0 0 4px !important;
      .mat-mdc-button-toggle-button {
        height: 58.5px;
        vertical-align: middle;
        width: -webkit-fill-available;
        text-align: center;
      }
    }
  }

  .inverse {
    .mat-mdc-button-toggle-group {
      border-radius: 0px 4px 4px 0px !important;
    }
    .mat-mdc-form-field-outline-end {
      border-radius: 0px !important;
    }
    .mat-mdc-form-field-outline-start {
      border-radius: 4px 0 0 4px !important;
    }
  }
  .mat-mdc-option-text,
  .mat-mdc-option.mat-active {
    color: mat.get-color-from-palette($primary, darker);
  }
}
