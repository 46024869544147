@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin global-theme($sps-app-theme) {
  $primary: map-get($sps-app-theme, primary);
  $accent: map-get($sps-app-theme, accent);
  $warning: map-get($sps-app-theme, warn);

//When setting spacing of elements, use divisions of 4px, this will keep layouts consistent.
//Global styles are styles that affect the whole app, not a specific component e.g. Adding a colour
//Additionally, styles that affect a single page should be put into the component.scss stylesheet.

  .jumbotron{
    background-color: mat.get-color-from-palette($accent, 900);
  }

    .mat-calendar{
    min-height: 377px!important;
    }
    .mat-mdc-button-toggle-label-content{
    line-height: 3.5 !important;
    }



}

//Bootstrap Utilities - e.g. Layouts and Colours
//https://getbootstrap.com/docs/4.4/utilities/borders/

//Angular Components
//https://material.angular.io/components/autocomplete/overview
