@use '@angular/material' as mat;
@import "@angular/material/theming";

@mixin table-theme($sps-app-theme) {
  $primary: map-get($sps-app-theme, primary);
  $accent: map-get($sps-app-theme, accent);
  $warning: map-get($sps-app-theme, warn);

  td.mat-column-actions {
    button {
      min-width: 0;
    }
  }

  .mat-mdc-paginator {
    border: 1px solid mat.get-color-from-palette($primary) !important;
  }

  table,
  .mat-mdc-table {
    border: 1px solid mat.get-color-from-palette($primary);
    border-radius: 4px;
    width: 100%;
    .material-icons {
      font-size: 16px;
    }
    .row {
      margin: 0;
    }

    th.mat-mdc-header-cell:last-of-type {
      text-align: right;
    }

    tr.mat-mdc-row {
      border-left: 4px solid #808080;
      box-sizing: border-box !important;
    }
    tr.mat-mdc-header-row {
      border-left: 4px solid gray;
    }

    .actions,
    td.mat-column-actions {
      button{
       height: 38px !important;
      }
      max-width: 150px !important;
      text-align: right !important;
      .material-icons {
        font-size: 24px;
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
    .content-column {
      max-width: 250px;
      .content-column-text {
        max-width: 200px;
      }
    }
    @include media-breakpoint-down(xl) {
      .content-column {
        max-width: 250px;
        .content-column-text {
          max-width: 100%;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .content-column {
        max-width: 150px;
        .content-column-text {
          max-width: 100%;
        }
      }
      .sm-column{
        max-width: 70px !important;
    }
    }
    @include media-breakpoint-down(md) {
      .content-column {
        max-width: 100px;
        .content-column-text {
          max-width: 100%;
        }
      }
      .sm-column{
        max-width: 70px !important;
    }
    }
  }

  .ctm-table {
    max-width: 100%;

    tr {
      display: flex;
      border-bottom: 0.5px solid mat.get-color-from-palette($primary);
      align-items: center;
      min-height: 48px;
      padding: 0 24px;
    }

    td,
    th {
      flex: 1;
      overflow: hidden;
      word-wrap: break-word;
    }

    th,
    .th {
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
    }

    .actions {
      max-width: 45px !important;
      .material-icons {
        font-size: 24px;
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }

  .coms-table {
    tr {
      min-height: 56px;
    }
    td {
      font-size: 20px;
      text-align: center;
    }
    th {
      text-align: center;
    }
  }
}
