@use '@angular/material' as mat;
@import "@angular/material/theming";

@mixin layout-theme($sps-app-theme) {
  $primary: map-get($sps-app-theme, primary);
  $accent: map-get($sps-app-theme, accent);
  $warning: map-get($sps-app-theme, warn);

  //When setting spacing of elements, use divisions of 4px, this will keep layouts consistent.
  //cards

  .mat-mdc-card {
    margin: 16px 0 16px 0;
    border-radius: 4;
    padding: 16px !important;
  }

  .card {
    [class*="col-"] {
      padding: 4px 8px;
      padding-bottom: 0px;
    }
    border-left: 4px solid mat.get-color-from-palette($primary);

    p {
      margin-bottom: 4px;
      color: mat.get-color-from-palette($primary, darker, 0.8);
      font-weight: 500;
      font-size: 80%;
    }
  }

  .card.overflow {
    overflow: auto;
    max-height: 55vh;
  }

  //thumbnail
  .mat-mdc-list-item-avatar,
  .thumbnail {
    height: 70px !important;
    width: 100px !important;
  }

  //cols/rows
  .row {
    margin: 8px 0;
    display: flex;
    align-content: center !important;
  }

  [class*="col-"] {
    padding: 4px 16px;
  }

  //lists

  //container

  .container, .container-lg {
    max-width: 1660px;
    margin-bottom: 40px;
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px;
  }
}
