@import '@angular/material/theming';

@mixin preview-theme($sps-app-theme) {
  $primary: map-get($sps-app-theme, primary);
  $accent: map-get($sps-app-theme, accent);
  $warning: map-get($sps-app-theme, warn);

  // .button:hover{
  //   opacity: 0.5;
  // }
  
  .button-right{
    position: absolute;
    top: 50%;
    opacity: 0.25;
    right: 0;
    z-index: 3;
  }
  
  .button-left{
    position: absolute;
    top: 50%;
    opacity: 0.25;
    left: 0;
    z-index: 2;
  }
  
  .page-count{
    position: absolute;
    top: 95%;
    opacity: 0.75;
    left: 49%;
    right: 49%;
    width: 70px;
    z-index: 2;
  }
  
  
  .slide{
    position: absolute;
    top: var(--slide-offset-top);
    left: var(--slide-offset-left);
    z-index: 1;
  }
  
  .fullScreen{
    background-color: black;
    height: 100%;
    width: 100%;
  }
  

}
